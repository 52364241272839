// Bootstrap Variables

// Application Variables
$fonts: (
  'Righteous-Regular':                ( family: 'Righteous',         file: 'Righteous-Regular',              weight: 400, style: normal ),
);

$font-weights: (
  'light': $font-weight-light,
  'normal': $font-weight-normal,
  'bold': $font-weight-bold
);

$headings: (
  'h1': ('font-size': $h1-font-size, 'letter-spacing': 0, 'line-height': 1),
  'h2': ('font-size': $h2-font-size, 'letter-spacing': 0, 'line-height': 1),
  'h3': ('font-size': $h3-font-size, 'letter-spacing': 0, 'line-height': 1),
  'h4': ('font-size': $h4-font-size, 'letter-spacing': 0, 'line-height': 1),
  'h5': ('font-size': $h5-font-size, 'letter-spacing': 0, 'line-height': 1),
  'h6': ('font-size': $h6-font-size, 'letter-spacing': 0, 'line-height': 1)
);

$font-sizes: (
  'lg': ('font-size': $font-size-lg),
  'md': ('font-size': $font-size-base * 1.125),
  'base': ('font-size': $font-size-base),
  'sm': ('font-size': $font-size-sm),
);

.font-weight-light {
  font-weight: $font-weight-light;
}

.font-weight-medium {
  font-weight: $font-weight-medium;
}

.font-weight-bold {
  font-weight: $font-weight-bold;
}