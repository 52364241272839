@import "~slick-carousel/slick/slick.css";
@import "~slick-carousel/slick/slick-theme.css";

body {
  background: white;
}

.app-container {
  min-height: 100vh;
  background-position: fixed;
  &.dark {
    // background-color: #231D5D;
    // background: linear-gradient(191deg, #231d5d 0%, #8989eb 100%);
    background-image: url("../../assets//imgs/bg-dark.png");
    background-size: cover;

    .app-header {
      // background: rgba(255, 255, 255, 0.06);
      color: white;

      .connection {
        background: #ffffff1a;
      }
    }
  }

  &.light {
    // background-color: white;
    // background: rgba(237, 71, 153, 0.22);
    background: linear-gradient(22.4deg, white 9.02%, #8989eb 103.71%);
    background-image: url("../../assets//imgs/bg-light.png");
    background-size: cover;

    .app-header {
      position: relative;
      z-index: 100;
      background: transparent;
      color: #231d5d;

      .connection {
        background: white;
        box-shadow: 0px 10px 18px 0px #aaaac81f;
      }
    }
  }

  .app-header {
    @media (max-width: 576px) {
      z-index: 100;
    }

    .logo-img {
      font-size: 36px;
      padding: 1rem 2rem;
    }

    .logo {
      font-size: 36px;
      font-family: Bebas Neue;
      font-weight: 400;
      letter-spacing: 0em;
    }

    .header-text {
      padding-left: 2rem;
    }

    .connection {
      padding: 5px;
      margin-right: 20px;

      @media (max-width: 1024px) {
        margin: 1px;
        margin-right: 7px;
      }
      @media (max-width: 576px) {
        font-size: 0.7rem;

        button {
          font-size: 10px !important;
        }

        .div-connect-btn {
          padding-left: 10px !important;

          & > div {
            margin-right: 10px !important;
          }
        }
      }

      border-radius: 50px;
      button {
        background-color: #00b9ba;
        border-radius: 50px;
        font-family: "Inter";
        font-size: 18px;
        font-weight: 600;
        text-transform: none;
        color: white;
      }
    }
  }
  .vertical-center {
    @media (max-width: 1024px) {
      margin: 0;
      position: absolute;
      top: 58%;
      left: 50%;
      -ms-transform: translate(-50%, -50%);
      transform: translate(-50%, -50%);
    }
    @media (max-width: 767px) {
      margin: 0;
      position: absolute;
      top: 58%;
      left: 0%;
      -ms-transform: translateY(-50%);
      transform: translateY(-50%);
    }
  }
  .message-box {
    max-width: 60%;
    @media (max-width: 1024px) {
      max-width: 70%;
    }
    @media (max-width: 768px) {
      max-width: 90%;
    }
    padding: 35px 40px;
    border-radius: 50px;
    background-color: white;
    .box-text {
      font-size: 24px;
      @media (max-width: 320px) {
        font-size: 18px;
      }
      font-weight: 400;
      text-align: center;
      margin-bottom: 30px;
    }
    button {
      background-color: $gray-600;
      border-radius: 50px;
      height: 45px;
      color: black;
      font-size: 18px;
      font-weight: 500;
      text-transform: capitalize;
    }
  }
  .switchNet-btn {
    font-size: 14px;
    font-family: "Rubik";
    font-weight: 400;
    color: black;
    background-color: #e5e5e5;
    border-radius: 20px;
    text-transform: capitalize;
    padding: 2px 2px;
  }
  .keys-group {
    margin: auto;
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 4rem;
    margin-bottom: 100px;

    @media (max-width: 1024px) {
      grid-template-columns: repeat(2, 1fr);
      gap: 2rem;
    }

    @media (max-width: 576px) {
      grid-template-columns: repeat(1, 1fr);
      padding-top: 2rem;
      padding-bottom: 2rem;
      gap: 24px;
    }

    .group-item {
      position: relative;

      .key-item {
        background: linear-gradient(0deg, #231e5e, #231e5e);
        left: 0;
        top: 0;
        padding: 20px 20px;
        border-radius: 10px;
        color: white;
        img {
          width: 250px;
          // height: 250px;
          margin-bottom: 10px;
        }
        .key-title {
          font-size: 25px;
          font-weight: 700;
          @media (max-width: 280px) {
            font-size: 1.3rem;
          }
        }
        .key-value {
          margin-top: 15px;
          margin-bottom: 15px;
          font-family: "Rubik" !important;

          @media (max-width: 280px) {
            margin-bottom: 10px;
          }
        }
        .setkey-btn {
          font-size: 36px;
          background-color: #00b9ba;
          width: 100%;
          @media (max-width: 280px) {
            font-size: 1.7rem;
          }
          font-family: "Rubik";
          font-weight: 700;
          color: white;
          border-radius: 30px;
          text-transform: capitalize;
          padding: 5px 20px;
        }
      }
    }
    .main-keys {
      @media (max-width: 1024px) {
        flex-direction: column;
      }
    }
  }
}
.header-text {
  @media (max-width: 1024px) {
    display: none;
  }
}
.w-60 {
  width: 60%;
}
.confirm-btn {
  font-size: 18px;

  @media (max-width: 540px) {
    font-size: 16px;
  }
  @media (max-width: 320px) {
    font-size: 14px;
  }
  font-family: "Inter";
  font-weight: 700;
  color: white;
  background-color: #ed4799;
  border-radius: 1rem;
  text-transform: capitalize;
  padding: 1.5rem 4rem;
}
.confirm-btn:hover {
  color: gray;
}
.return-btn {
  width: 25% !important;
  @media (max-width: 768px) {
    width: 60% !important;
  }
}

.spin-center {
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;

  @media (max-width: 576px) {
    margin-left: 1rem;
    margin-right: 1rem;
  }
  margin-left: 2rem;
  margin-right: 2rem;

  .outer-circle,
  .inner-circle {
    border-radius: 50%;
  }

  .outer-circle {
    border: 6px solid #f4f5f70f;
    width: 150px;
    height: 150px;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .inner-circle {
    border: 2px solid #f4f5f70f;
    width: 110px;
    height: 110px;
    display: flex;
    align-items: center;
    justify-content: center;

    .key-wrapper {
      display: flex;
      align-items: center;

      img {
        width: 40px;
        height: 40px;
      }

      input {
        font-size: 20px;
      }
    }
  }
}

img {
  object-fit: cover;
}

.spin-btn {
  font-size: 2.3rem;
  @media (max-width: 540px) {
    font-size: 1.4rem;
  }
  @media (max-width: 320px) {
    font-size: 1.2rem;
  }
  font-family: "Rubik";
  font-weight: 400;
  color: white;
  background-color: #ed4799;
  text-transform: capitalize;
  width: 50px;
  height: 50px;
  border-radius: 8px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.spin-btn:hover {
  color: gray;
}
.swal2-modal {
  background: #231e5e;
  color: white;
}

.spinvalue-size {
  width: 40px;
  background: transparent;
  border: none !important;
  outline: none;
  padding-top: 0.2rem;
  z-index: 1;
}
.Modal {
  font-family: "Rubik";
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  height: 50vh;
  width: 40vw;
  @media (max-width: 1024px) {
    width: 50vw;
  }
  @media (max-width: 414px) {
    width: 85vw;
  }
  @media (max-width: 280px) {
    width: 95vw;
  }
  margin-right: -50%;
  border-radius: 2rem;
  padding-bottom: 5rem;

  & > .d-flex {
    @media (max-width: 576px) {
      width: 90% !important;
    }
  }

  &.dark {
    background-color: #231d5d;
    color: white;
    .sub-title {
      color: #92d2c2;
    }

    .inner-circle,
    .outer-circle {
      border-color: #f4f5f70f !important;
    }

    .spinvalue-size {
      color: white;
    }
  }

  &.light {
    background-color: white;
    color: #231d5d;
    .sub-title {
      color: #7054a1;
    }

    .inner-circle,
    .outer-circle {
      border-color: #f4f5f7 !important;
    }

    .spinvalue-size {
      color: #231e5e;
    }
  }

  .main-title {
    font-family: Bebas Neue;
    font-size: 40px;
    font-weight: 400;
    line-height: 48px;
    letter-spacing: -0.02em;
    text-align: center;

    @media (max-width: 414px) {
      font-size: 24px;
    }
  }

  .modal-close {
    position: absolute;
    top: 2rem;
    right: 2rem;
    cursor: pointer;
    font-size: 30px;

    @media (max-width: 576px) {
      font-size: 24px;
      top: 1rem;
      right: 1rem;
    }
  }
}

.swal2-container {
  z-index: 10000000 !important;
}

.Overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 100000;
}
.font-2 {
  font-size: 2rem;
  @media (max-width: 1439px) {
    font-size: 1.8rem;
  }
  @media (max-width: 540px) {
    font-size: 1.4rem;
  }
  @media (max-width: 320px) {
    font-size: 1.2rem;
  }
}
.font-1 {
  font-size: 1.4rem;
  font-family: "Rubik";
  @media (max-width: 1439px) {
    font-size: 1.2rem;
  }
  @media (max-width: 540px) {
    font-size: 1rem;
  }
  @media (max-width: 320px) {
    font-size: 0.8rem;
  }
}
.font-0-1 {
  font-size: 0.9rem;
}
.font-0-2 {
  font-size: 1.2rem;
}
////////// Spinner //////////
.cssload-container {
  width: 100%;
  height: 43px;
  @media (max-width: 1024px) {
    height: 20px;
  }
  text-align: center;
}

.cssload-zenith {
  width: 43px;
  height: 43px;
  @media (max-width: 1024px) {
    width: 20px;
    height: 20px;
  }
  margin: 0 auto;
  border-radius: 50%;
  border-top-color: transparent;
  border-left-color: transparent;
  border-right-color: transparent;
  box-shadow: 3px 3px 1px rgb(255, 255, 255);
  animation: cssload-spin 690ms infinite linear;
  -o-animation: cssload-spin 690ms infinite linear;
  -ms-animation: cssload-spin 690ms infinite linear;
  -webkit-animation: cssload-spin 690ms infinite linear;
  -moz-animation: cssload-spin 690ms infinite linear;
}
.cssload-zenith-black {
  box-shadow: 3px 3px 1px rgb(0, 0, 0);
}

@keyframes cssload-spin {
  100% {
    transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

@-o-keyframes cssload-spin {
  100% {
    -o-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

@-ms-keyframes cssload-spin {
  100% {
    -ms-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

@-webkit-keyframes cssload-spin {
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

@-moz-keyframes cssload-spin {
  100% {
    -moz-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
//////////////////////////////////////////

.w-20 {
  width: 20%;
}
.w-90 {
  width: 90%;
}
.w-80 {
  width: 80%;
}

.lootbox {
  border-radius: 2rem;
  height: 100%;
}
.item-image {
  height: 90%;
}
.return-btn {
  min-width: 150px;
}
.slick-prev:before,
.slick-next:before {
  color: black;
  font-size: 2rem;
}
.slick-prev {
  left: -35px;
}
/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type="number"] {
  -moz-appearance: textfield;
}

.resultBox-flex {
  margin-top: 5%;
  display: flex !important;
  @media (max-width: 1024px) {
    display: block !important;
  }
}
.div-connect-btn {
  padding-left: 20px;
  font-family: "Inter";
  @media (max-width: 320px) {
    padding-left: 10px;
    margin-right: -25px;
  }
}
.connect-btn {
  font-size: 1rem;
  @media (max-width: 280px) {
    font-size: 0.8rem !important;
  }
}
.text-1 {
  padding-bottom: 24px !important;
  font-weight: 800;
  @media (max-width: 320px) {
    padding-bottom: 7px !important;
  }
}
.text-2 {
  padding-left: 48px;
  @media (max-width: 280px) {
    padding-left: 24px;
  }
}
.add-btn {
  @media (max-width: 768px) {
    font-size: 1.3rem !important;
    padding: 0.1rem 1rem;
  }
  @media (max-width: 540px) {
    font-size: 1.4rem !important;
    padding: 0.1rem 1rem;
  }
  @media (max-width: 375px) {
    font-size: 1.2rem !important;
    padding: 0.1rem 1rem;
  }
  @media (max-width: 360px) {
    font-size: 1.1rem !important;
    padding: 0.1rem 1rem;
  }
  @media (max-width: 320px) {
    font-size: 0.9rem !important;
    padding: 0.1rem 1rem;
  }
}
.Modal-style {
  padding: 4rem 0rem !important;
  @media (max-width: 1024px) {
    padding: 1rem 0rem !important;
  }
}
