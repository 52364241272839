.side-bar {
  position: fixed;
  left: 0;
  width: 100px;
  padding: 1rem;
  height: 100%;

  @media (max-width: 576px) {
    background: transparent!important;
  }

  
  &.dark {
    background: rgba(255, 255, 255, 0.04);
    z-index:3;
  }
  
  &.light {
    background: rgba(255, 255, 255, 0.60);
    z-index: 10;
  }

  .inner-wrapper {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: flex-end;

    .switch {
      position: relative;
      display: inline-block;
      width: 60px;
      height: 34px;

      .images {
        display: flex;
        align-items: center;
        justify-content: space-around;
        margin-top: -10px;

        img {
          position: relative;
          z-index: 10000;
        }
      }
    }
    
    .switch input { 
      opacity: 0;
      width: 0;
      height: 0;
    }
    
    .slider {
      position: absolute;
      cursor: pointer;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      background-color: white;
      box-shadow: 0px 4px 20px 0px #00000014;
      -webkit-transition: .4s;
      transition: .4s;
    }
    
    .slider:before {
      position: absolute;
      content: "";
      height: 26px;
      width: 26px;
      left: 4px;
      bottom: 4px;
      background-color: #EE4699;
      -webkit-transition: .4s;
      transition: .4s;
    }
    
    input:checked + .slider {
      // background-color: #EE4699;
    }
    
    input:focus + .slider {
      // box-shadow: 0 0 1px #EE4699;
    }
    
    input:checked + .slider:before {
      -webkit-transform: translateX(26px);
      -ms-transform: translateX(26px);
      transform: translateX(26px);
    }
    
    /* Rounded sliders */
    .slider.round {
      border-radius: 34px;
    }
    
    .slider.round:before {
      border-radius: 50%;
    }
  }
}