.header-container {
  background-color: $dark;
  border-bottom: 2px solid $primary;
  position: fixed;
  width: 100%;
  top: 0;
  z-index: 1000;
  .header-navbar {
    padding: 20px 0;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    .collapse-menu {
      position: absolute;
      top: 114px;
      right: 0;
      height: 0;
      width: 0;
      opacity: 0;
      z-index: -1000;
      display: none;
    }
    .toggler {
      position: absolute;
      right: 0;
      bottom: 20px;
      width: 50px;
      cursor: pointer;
      -webkit-transition: .25s ease-in-out;
      -moz-transition: .25s ease-in-out;
      -o-transition: .25s ease-in-out;
      transition: .25s ease-in-out;
      div {
        width: 100%;
        height: 3px;
        border-radius: 1px;
        background-color: $white;
        -webkit-transition: .2s ease-in-out;
        -moz-transition: .2s ease-in-out;
        -o-transition: .2s ease-in-out;
        transition: .2s ease-in-out;
        &:last-child {
          margin-top: 15px;
        }
      }
    }
    .header-logo {
      cursor: pointer;
      img {
        width: 80px;
        height: auto;
      }
    }
  }
  
  @include media-breakpoint-down('sm') {
    .header-navbar {
      .toggler {
        width: 40px;
        margin-bottom: 5px;
        div {
          height: 2px;
          &:last-child {
            margin-top: 7.5px;
          }
        }
      }
    }
  }
}

.collapsed {
  .header-container {
    .header-navbar {
      .toggler {
        margin-bottom: 10px;
        div:first-child {
          transform: rotate(45deg);
          margin-top: 15px;
        }
        div:last-child {
          transform: rotate(-45deg);
          margin-top: -3px;
          @include media-breakpoint-down('sm') {
            margin-top: -1px;
          }
        }
      }
      .collapse-menu {
        max-width: 375px;
        width: 100%;
        opacity: 1;
        display: flex;
        flex-direction: column;
        z-index: 10000;
        transition: all .5s;
        .menu-item {
          padding: 15px 40px;
          background-color: $primary;
          border-bottom: 1px solid $dark;
          font-weight: $font-weight-medium;
          color: $dark;
          font-size: 20px;
          display: flex;
          align-items: center;
          &:hover {
            text-decoration: none;
          }
          .flag {
            width: 30px;
            height: auto;
          }
        }
        .close-action {
          position: absolute;
          right: 20px;
          top: 20px;
          cursor: pointer;
          z-index: 2;
          display: flex;
          align-items: center;
          font-size: 20px;
          color: $dark;
          img {
            width: 20px;
            height: 20px;
          }
        }
      }
    }
  }
}