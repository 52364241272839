body {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  overflow-x: hidden !important;
  width: 100%;
  min-height: 100vh;
  font-family: 'Rubik' !important;
  .layout {
    position: relative;
    overflow-y: auto;
    overflow-x: hidden;
    width: 100%;
    min-height: 100vh;
    scroll-behavior: smooth;
  }
}

.text-underline {
  text-decoration: underline;
}

.text-uppercase {
  text-transform: uppercase;
}