.footer-container {
  background-color: #1c184b;
  display: flex;
  padding: 4px;
  align-items: center;
  justify-content: flex-end;

  #random {
    width: 190px;
    margin-left: 16px;

    img {
      height: 50px;
    }
  }

  .social-icon {
    margin-right: 8px;
    img {
      height: 24px;
      width: 24px;
    }
  }
}
