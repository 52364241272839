@each $font-key, $font-item in $fonts {
  $font-family: map-get($font-item, 'family');
  $font-weight: map-get($font-item, 'weight');
  $font-style: map-get($font-item, 'style');
  $font-file: map-get($font-item, 'file');
  @font-face {
    font-family: $font-family;
    src: url('../../assets/fonts/'+ $font-file + '.ttf')  format('truetype');
    font-weight: $font-weight;
    font-style: $font-style;
  }

  @font-face {
    font-family: 'Rubik';
    src: url('../../assets/fonts/rubik-v21-latin-regular.ttf');
    font-weight: $font-weight;
    font-style: $font-style;
  }

  @font-face {
    font-family: 'Molot';
    src: url('../../assets/fonts/Molot.otf');
    font-weight: $font-weight;
    font-style: $font-style;
  }

  @font-face {
    font-family: 'Bebas Neue';
    src: url('../../assets/fonts/BebasNeue-Regular.ttf');
    font-weight: $font-weight;
    font-style: $font-style;
  }

  @font-face {
    font-family: 'Inter';
    src: url('../../assets/fonts/Inter-VariableFont_slnt\,wght.ttf');
    font-weight: $font-weight;
    font-style: $font-style;
  }
}