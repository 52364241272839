@each $heading, $heading-info in $headings {
  #{$heading} {
    @each $key, $value in $heading-info {
      #{$key}: $value;
    }
  }
}

@each $breakpoint in map-keys($grid-breakpoints) {
  @include media-breakpoint-up($breakpoint) {
    $infix: breakpoint-infix($breakpoint, $grid-breakpoints);
    @each $heading, $heading-info in $headings {
      .#{$heading}#{$infix} {
        @each $key, $value in $heading-info {
          #{$key}: $value;
        }
      }
    }
  }
}

@each $key, $info in $font-sizes {
  .font-size-#{$key} {
    @each $key, $value in $info {
      #{$key}: $value !important;
    }
  }
}

@each $key, $info in $font-weights {
  .font-weight-#{$key} {
    @each $key, $value in $info {
      #{$key}: $value !important;
    }
  }
}